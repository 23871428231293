<template>
  <div class="mx-5">
    <h2 class="mt-5 text-yellow-500 text-lg uppercase font-semibold">
      Popularni filmi
    </h2>
    <div
      class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-8"
    >
      <MovieItem
        v-for="movie in movies"
        :key="movie.id"
        :movie="movie"
        :genres="genres"
      />
    </div>
  </div>
</template>

<script>
import MovieItem from "../items/MovieItem";
export default {
  data() {
    return {
      movies: [],
      genres: [],
    };
  },
  components: {
    MovieItem,
  },

  async mounted() {
    this.fetchGenres();
    try {
      const response = await this.$http.get("/movie/popular");
      this.movies = response.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async fetchGenres() {
      try {
        const response = await this.$http.get("/genre/movie/list");
        this.genres = response.data.genres;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
