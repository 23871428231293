<template>
  <div class="container mx-auto border-b border-gray-600 px-4 py-4">
    <h2 class="text-4xl font-semibold mb-5">Igralci</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div v-for="cast in casts" :key="cast.id" class="mr-2">
        <router-link :to="`/actor/${cast.id}`">
          <img v-if="cast.profile_path" :src="castProfileImage(cast)" alt="" />
          <img
            v-else
            src="@/assets/images/male-placeholder-image.jpeg"
            alt=""
          />

          <span class="text-gray-300"> {{ cast.name }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    casts: {
      required: true,
    },
  },

  methods: {
    castProfileImage(cast) {
      if (cast.profile_path) {
        return "https://image.tmdb.org/t/p/w300/" + cast.profile_path;
      }
    },
  },
};
</script>

<style></style>
