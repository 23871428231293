<template>
  <div class="container mx-auto px-4 py-16">
    <h2 class="text-yellow-500 text-lg font-semibold">POPULARNI IGRALCI</h2>

    <div
      class="justify-items-center mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
    >
      <ActorItem v-for="(actor, index) in actors" :key="index" :actor="actor" />
    </div>
    <div class="text-center mt-5">
      <a v-if="currPage > 1" href="" v-on:click.prevent="previous()">
        &#8592; Nazaj
      </a>
      <a href="" v-on:click.prevent="next()" class="ml-5"> Naprej &#8594; </a>
    </div>
    <!-- <div class="flex justify-center">
      <div class="spinner">11</div>
    </div> -->
  </div>
</template>

<script>
let currentPage = 1;
import { mapActions } from "vuex";
import ActorItem from "../items/ActorItem.vue";
export default {
  data() {
    return {
      actors: [],
      currPage: currentPage,
    };
  },
  components: { ActorItem },
  mounted() {
    this.fetchActors(currentPage);
    this.setSearchType("person");
  },

  methods: {
    ...mapActions(["setSearchType"]),
    async fetchActors(page) {
      try {
        const response = await this.$http.get(
          "https://api.themoviedb.org/3/person/popular?page=" + page
        );
        this.actors = response.data.results.filter(
          (actor) => actor.profile_path
        );
        // this.actors.push(...response.data.results);
      } catch (error) {
        console.log(error);
      }
    },

    /*     scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          currentPage += 1;
          this.fetchActors((currentPage += 1));
        }
      };
    }, */
    next() {
      currentPage += 1;
      this.fetchActors(currentPage);
      this.currPage = currentPage;
    },
    previous() {
      currentPage -= 1;
      this.fetchActors(currentPage);
      this.currPage = currentPage;
    },
  },
};
</script>

<style></style>
