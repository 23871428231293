<template>
  <div id="example">
    <h2 class="uppercase text-yellow-500 font-semibold text-lg mt-20 ml-2">
      Kmalu v kinematografih
    </h2>
    <carousel-3d
      :controls-visible="true"
      :clickable="false"
      :listData="upcomingMovies"
      :height="500"
      :key="upcomingMovies.length"
    >
      <slide v-for="(movie, i) in upcomingMovies" :key="i" :index="i">
        <router-link :to="`/movie/${movie.id}`">
          <figure>
            <img
              :src="'https://image.tmdb.org/t/p/w500/' + movie.poster_path"
            />
            <figcaption>
              {{ movie.title }}
            </figcaption>
          </figure>
        </router-link>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  data() {
    return {
      upcomingMovies: [],
    };
  },
  components: {
    Carousel3d,
    Slide,
  },
  mounted() {
    this.fetchUpcommingMovies();
  },
  methods: {
    async fetchUpcommingMovies() {
      const response = await this.$http.get(
        "https://api.themoviedb.org/3/movie/upcoming/"
      );
      this.upcomingMovies = response.data.results;
      // console.log(response.data);
    },
  },
};
</script>

<style>
.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}

.next span,
.prev span {
  color: white;
}
</style>
