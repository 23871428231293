<template>
  <div class="flex">
    <router-link to="/">
      <img src="@/assets/images/logo.png" alt=""
    /></router-link>

    <ul class="flex mt-5">
      <li @click.prevent="setSearchType('movie')">
        <router-link :to="{ name: 'home' }" class="ml-5">Filmi</router-link>
      </li>
      <li @click.prevent="setSearchType('person')">
        <router-link :to="{ name: 'actors' }" class="ml-5">Igralci</router-link>
      </li>
      <li>
        <router-link
          v-if="this.$store.getters.isUserLoggedIn"
          to="/profile"
          class="ml-5"
          >Profil</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    setSearchType(type) {
      this.$store.dispatch("setSearchType", type);
    },
  },
};
</script>

<style></style>
