import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserLoggedIn: true,
    searchTypeIsActor: false,
    search: {
      type: "movie",
      searchTerm: "",
    },
  },
  mutations: {
    SET_USER_LOGGED_IN(state, payload) {
      state.isUserLoggedIn = payload;
    },
    SET_SEARCH_TYPE(state, payload) {
      state.search.type = payload;
      state.search.searchTerm = "";
    },
    SET_SEARCH_TERM(state, payload) {
      state.search.searchTerm = payload;
    },
  },
  actions: {
    setUserLoggedIn({ commit }, loggedIn) {
      commit("SET_USER_LOGGED_IN", loggedIn);
    },
    setSearchType({ commit }, searchType) {
      commit("SET_SEARCH_TYPE", searchType);
    },
    setSearchTerm({ commit }, searchTerm) {
      commit("SET_SEARCH_TERM", searchTerm);
    },
  },
  getters: {
    isUserLoggedIn(state) {
      return state.isUserLoggedIn;
    },
    searchType(state) {
      return state.search.type;
    },
    searchTerm(state) {
      return state.search.searchTerm;
    },
  },
});
